// src/hooks/useAuthorization.ts
import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import locations, { LocationConfig } from "@/data/locations";

// authorized user manager groupid
const groupId = "8cba404f-a3d3-4676-9923-1484786936ce";

function useAuthorization() {
  const { data: session, status } = useSession();
  const [authState, setAuthState] = useState({ isAuthorized: false, isLoaded: false });
  const [location, setLocation] = useState<LocationConfig | null>(null);

  useEffect(() => {
    if (status === "authenticated") {
      const authorized = session?.user.groups.includes(groupId);
      setLocation(getLocationGroup(session?.user.groups));
      setAuthState({ isAuthorized: authorized ?? false, isLoaded: true });
    } else if (status === "unauthenticated") {
      setAuthState({ isAuthorized: false, isLoaded: true });
    }
  }, [session, status]);

  return { ...authState, session, status, location };
}

export default useAuthorization;

function getLocationGroup(groups: string[]): LocationConfig | null {
  // see if any groups match one of the locations.id. return the location
  const l = locations.find((loc) => groups.includes(loc.id));
  // l && console.log("Location identified: ", l);
  return l ? l : null;
}
